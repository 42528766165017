import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UriUtils } from '../../shared/utils/uri.util';
import { take, Subject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseModel } from '../models/base.model';
import { Pagination } from '../interfaces/pagination.interface';
import { Order } from '../interfaces/order.interface';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService<T extends BaseModel> {
    private itemUpdatedSubject = new Subject<T>();

    constructor(protected url: string, protected _httpClient: HttpClient, protected _userService?: UserService) {}

    create(t: T): Observable<T> {
        return this._httpClient.post<T>(UriUtils.buildUrl(this.url), t).pipe(take(1));
    }

    update(id: number, t: T): Observable<T> {
        return this._httpClient.patch<T>(UriUtils.buildUrl(this.url, id.toString()), t).pipe(take(1));
    }

    delete(id: number) {
        return this._httpClient.delete<T>(UriUtils.buildUrl(this.url, id.toString())).pipe(take(1));
    }

    findOne(id: number) {
        return this._httpClient.get<T>(UriUtils.buildUrl(this.url, id.toString())).pipe(take(1));
    }

    find(t?: T) {
        if(this._userService) t.isOwner = this._userService.currentUser.role === "Propietario" ? true : false;
        const params = new HttpParams().set('filter', JSON.stringify(t));
        return this._httpClient.get<T[]>(UriUtils.buildUrl(this.url), { params }).pipe(take(1));
    }

    findOneWithRelations(id: number) {
        return this._httpClient.get<T>(UriUtils.buildUrl(this.url, id.toString(), 'relations')).pipe(take(1));
    }

    findIn(t?: T, page: number = 1, limit: number = 10, order: Order = { column: 'name', typeOrder: 'ASC' }) {
        if(this._userService) t.isOwner = this._userService.currentUser.role === "Propietario" ? true : false;
        const params = new HttpParams().set('filter', JSON.stringify(t)).set('order', JSON.stringify(order));
        return this._httpClient
            .get<Pagination<T>>(UriUtils.buildUrl(this.url, 'findIn', page.toString(), limit.toString()), { params })
            .pipe(take(1));
    }

    getUpdatedObservable(): Observable<T> {
        return this.itemUpdatedSubject.asObservable();
    }

    createObservable(item: T): Observable<T> {
        return this.create(item).pipe(
            switchMap((savedItem) => {
                this.itemUpdatedSubject.next(savedItem);
                return of(savedItem);
            })
        );
    }

    updateObservable(id: number, item: T): Observable<T> {
        return this.update(id, item).pipe(
            switchMap((updatedItem) => {
                this.itemUpdatedSubject.next(updatedItem);
                return of(updatedItem);
            })
        );
    }

    catalog(order: Order = { column: 'name', typeOrder: 'ASC' }) {
        let params = new HttpParams().set('order', JSON.stringify(order));
        return this._httpClient.get<T[]>(UriUtils.buildUrl(this.url, 'catalog'), { params }).pipe(take(1));
    }
}
