import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UriUtils } from '../../shared/utils/uri.util';
import { Observable, take } from 'rxjs';
import { Order } from '../interfaces/order.interface';
import { Pagination } from '../interfaces/pagination.interface';
import { UserService } from '../user/user.service';
import { BaseModel } from '../models/base.model';

@Injectable({
    providedIn: 'root'
})
export abstract class BaseCoreService<T extends BaseModel> {
    constructor(private url: string, private _httpClient: HttpClient, private _userService?: UserService) {}

    findOne(id: number): Observable<T> {
        return this._httpClient.get<T>(UriUtils.buildUrl(id.toString())).pipe(take(1));
    }

    find(t?: T): Observable<T[]> {
        if(this._userService) t.isOwner = this._userService.currentUser.role === "Propietario" ? true : false;
        const params = new HttpParams().set('filter', JSON.stringify(t));
        return this._httpClient.get<T[]>(UriUtils.buildUrl(this.url), { params }).pipe(take(1));
    }

    catalog(order: Order = { column: 'name', typeOrder: 'ASC' }): Observable<T[]> {
        let params = new HttpParams().set('order', JSON.stringify(order));
        return this._httpClient.get<T[]>(UriUtils.buildUrl(this.url, 'catalog'), { params }).pipe(take(1));
    }

    findIn(t?: T, page: number = 1, limit: number = 10, order: Order = { column: 'name', typeOrder: 'ASC' }): Observable<Pagination<T>> {
        if(this._userService) t.isOwner = this._userService.currentUser.role === "Propietario" ? true : false;
        const params = new HttpParams().set('filter', JSON.stringify(t)).set('order', JSON.stringify(order));
        return this._httpClient
            .get<Pagination<T>>(UriUtils.buildUrl(this.url, 'findIn', page.toString(), limit.toString()), { params })
            .pipe(take(1));
    }

    findOneWithRelations(id: number) {
        return this._httpClient.get<T>(UriUtils.buildUrl(this.url, id.toString(), 'relations')).pipe(take(1));
    }
}
